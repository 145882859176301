export const ASIDES = {
    account: {
        id: 'account',
        expandedWidth: '252px',
        collapsedWidth: '64px',
    },
    cms: {
        id: 'cms',
        expandedWidth: '252px',
        collapsedWidth: '64px',
    },
};

export const AREAS = {
    account: 'account',
    cms: 'cms',
};
